<template>
  <div class="row items-start q-col-gutter-sm">
    <div class="col-xs-12 col-sm-12 col-md-8">
      <div class="column q-col-gutter-sm q-ma-md">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SegmentFormContainer'
}
</script>
