<template>
  <Modal
    :header="title"
    :show="show"
    :loadingSubmit="loading"
    @close="close"
    @submit="submit"
  >
    <q-form ref="customerSegmentConfirmForm">
      <q-card flat>
        <q-card-section>
          <slot />
        </q-card-section>
      </q-card>
    </q-form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'ConfirmCustomerSegmentModal',
  emits: ['close', 'submit'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Save Customer Segment'
    }
  },
  components: { Modal },
  setup(props, { emit }) {
    const close = () => {
      emit('close')
    }

    const submit = () => {
      const data = {
        id: props?.data?.id
      }
      emit('submit', data)
    }

    return {
      close,
      submit
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: block;
  list-style: disc outside none;
  padding: 0 0 0 40px;
}
</style>
