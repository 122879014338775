<template>
  <CustomerSegmentCreate />
</template>

<script>
import CustomerSegmentCreate from '@/components/pages/customer/CustomerSegmentCreate'

export default {
  components: {
    CustomerSegmentCreate
  }
}
</script>
