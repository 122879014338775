<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Customer"
        :title="`${isUpdatePage ? 'Update' : 'Create'} Customer Segment`"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        icon="arrow_back"
        size="lg"
        outline
        rounded
        @click="goToListsPage"
      >
        <Tooltip text="Go back to lists" />
      </Button>

      <Button
        variant="primary"
        label="Save Segment"
        size="lg"
        outline
        :loading="isLoading"
        :disabled="isLoading"
        @click="onValidateSubmit"
        @mousedown="toggleCreate"
        v-if="segmentStep === segmentStepEnd"
      >
        <template v-slot:icon>
          <q-icon name="add_circle" size="md" />
        </template>
      </Button>

      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <Loader :loading="loadingLists" v-if="loadingLists" />
    <Notif
      variant="danger"
      message="You are not allowed to update this campaign"
      :visible="isUpdatePage && !isUpdateAllowed"
      noClose
      v-else-if="isUpdatePage && !isUpdateAllowed"
    />
    <q-form ref="filterForm" v-else>
      <q-stepper
        v-model="segmentStep"
        ref="stepper"
        color="primary"
        animated
        alternative-labels
      >
        <q-step
          class="stepHeight"
          :name="1"
          title="Set Segment Details"
          icon="settings"
          :done="segmentStep > 1"
        >
          <SegmentFormContainer>
            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Segment Code</label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormInput
                  v-model="segmentCode"
                  label="Segment Code"
                  maxlength="20"
                  :rules="requiredField"
                  tabindex="1"
                  outlined
                  counter
                />
              </div>
            </div>

            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Segment Name</label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormInput
                  v-model="segmentName"
                  label="Segment Name"
                  :rules="requiredField"
                  tabindex="2"
                  outlined
                />
              </div>
            </div>

            <div class="row items-start q-col-gutter-sm">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Remarks</label>
              </div>
              <div class="col-xs-12 col-sm-9 col-md-9">
                <FormInput
                  v-model="segmentRemarks"
                  type="textarea"
                  label="Remarks"
                  :rules="requiredField"
                  tabindex="3"
                  outlined
                  autogrow
                />
              </div>
            </div>
          </SegmentFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="2"
          title="Select Customer Type"
          icon="groups"
          :done="segmentStep > 2"
        >
          <SegmentFormContainer>
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">* Customer Type</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-12 col-md-6">
                    <FormSelect
                      label="My Customers / Non-Customers"
                      v-model="customerType"
                      :values="customerType"
                      :options="customerOptions"
                      :rules="[VALIDATION.required]"
                      tabindex="4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SegmentFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="3"
          title="Select Filters"
          icon="assignment"
          :done="segmentStep > 3"
        >
          <SegmentFormContainer>
            <div
              class="row items-start q-col-gutter-md"
              v-if="customerType && !isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">* Purchase Month</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Purchase Month From"
                      mask="####/##"
                      v-model="customerDateFrom"
                      defaultView="YearMonth"
                      :value="customerDateFrom"
                      :rules="[VALIDATION.required]"
                      @input="onSelectCustomerDateFrom"
                      tabindex="5"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Purchase Month To"
                      mask="####/##"
                      v-model="customerDateTo"
                      defaultView="YearMonth"
                      :value="customerDateTo"
                      :rules="[VALIDATION.required]"
                      @input="onSelectCustomerDateTo"
                      tabindex="5"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row items-start q-col-gutter-md" v-if="isMyCustomers">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">* Purchase Time Frame</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Date From"
                      mask="date"
                      v-model="date.dateFrom"
                      :value="date.dateFrom"
                      :rules="[VALIDATION.required]"
                      @input="onSelectFromDate"
                      tabindex="6"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Date To"
                      mask="date"
                      v-model="date.dateTo"
                      :value="date.dateTo"
                      :disabled="!date.dateFrom"
                      :options="date => date <= maxDate && date >= minDate"
                      :rules="[VALIDATION.required]"
                      @input="onSelectToDate"
                      tabindex="7"
                      hint="Max of 6 months from selected date from"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="add"
                  round
                  @click="addTime2"
                  v-if="!enabledTime2"
                  tabindex="8"
                >
                  <Tooltip text="Add Did Not Buy Time Frame" />
                </Button>
              </div>
            </div>

            <div
              class="row items-start q-col-gutter-md"
              v-if="enabledTime2 && isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">* Did Not Buy Time Frame</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Date From"
                      mask="date"
                      v-model="date2.dateFrom"
                      :value="date2.dateFrom"
                      :rules="[VALIDATION.required]"
                      @input="onSelectFromDate2"
                      tabindex="9"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormDate
                      label="Date To"
                      mask="date"
                      v-model="date2.dateTo"
                      :value="date2.dateTo"
                      :disabled="!date2.dateFrom"
                      :options="date => date <= maxDate2 && date >= minDate2"
                      :rules="[VALIDATION.required]"
                      @input="onSelectToDate2"
                      tabindex="10"
                      hint="Max of 6 months from selected date from"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="remove"
                  round
                  @click="removeTime2"
                  v-if="enabledTime2"
                  tabindex="11"
                >
                  <Tooltip text="Remove this Time Frame filter" />
                </Button>
              </div>
            </div>

            <div class="row items-start q-col-gutter-md" v-if="isMyCustomers">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">* Store</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-12 col-md-12">
                    <SelectStores
                      class="max-w-xl mb-2"
                      v-model="store.values"
                      option-value="value"
                      option-label="label"
                      :values="store.values"
                      :rules="[VALIDATION.requiredArray]"
                      :multiple="true"
                      @updateStore="$event => (store.values = $event)"
                      @getOptions="onGetStoreOptions"
                      tabindex="12"
                      rawData
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row items-start q-col-gutter-md"
              v-if="enabledProduct1 && isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Product</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="Bought/Did Not Buy"
                      v-model="product.bought"
                      :values="product.bought"
                      :options="boughtOptions"
                      :rules="[]"
                      tabindex="13"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <SelectProducts
                      class="max-w-xl mb-2"
                      v-model="product.value"
                      :values="product.value"
                      :disabled="product.bought === null"
                      :rules="[]"
                      tabindex="14"
                      rawData
                      @updateStore="onSelectSKU"
                    />
                    <Tag
                      variant="default"
                      @remove="onRemoveSKU(tag.value)"
                      v-for="tag in product.values"
                      :key="tag?.value"
                      :text="`${tag?.value} - ${tag?.label}`"
                      removable
                      tooltip
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="add"
                  round
                  @click="addProduct2"
                  v-if="!enabledProduct2"
                  tabindex="15"
                >
                  <Tooltip text="Add another Product filter" />
                </Button>
                <Button
                  variant="primary"
                  icon="remove"
                  round
                  @click="removeProduct1"
                  v-if="enabledProduct1 && enabledProduct2"
                  tabindex="16"
                >
                  <Tooltip text="Remove this Product filter" />
                </Button>
              </div>
            </div>

            <div
              class="row items-start q-col-gutter-md"
              v-if="enabledProduct2 && isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Product</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="Bought/Did Not Buy"
                      v-model="product2.bought"
                      :values="product2.bought"
                      :options="boughtOptions"
                      :rules="[]"
                      tabindex="17"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <SelectProducts
                      class="max-w-xl mb-2"
                      v-model="product2.value"
                      :values="product2.value"
                      :disabled="product2.bought === null"
                      :rules="[]"
                      tabindex="18"
                      rawData
                      @updateStore="onSelectSKU2"
                    />
                    <Tag
                      variant="default"
                      @remove="onRemoveSKU2(tag.value)"
                      v-for="tag in product2.values"
                      :key="tag?.value"
                      :text="`${tag?.value} - ${tag?.label}`"
                      removable
                      tooltip
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="add"
                  round
                  @click="addProduct1"
                  v-if="!enabledProduct1"
                  tabindex="19"
                >
                  <Tooltip text="Add another Product filter" />
                </Button>
                <Button
                  variant="primary"
                  icon="remove"
                  round
                  @click="removeProduct2"
                  v-if="enabledProduct1 && enabledProduct2"
                  tabindex="20"
                >
                  <Tooltip text="Remove this Product filter" />
                </Button>
              </div>
            </div>

            <div
              class="row items-start q-col-gutter-md"
              v-if="enabledBrand1 && isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Brand</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="Bought/Did Not Buy"
                      v-model="brand.bought"
                      :values="brand.bought"
                      :options="boughtOptions"
                      :rules="[]"
                      tabindex="21"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <SelectBrands
                      class="max-w-xl mb-2"
                      v-model="brand.values"
                      option-value="value"
                      option-label="label"
                      :values="brand.values"
                      :disabled="brand.bought === null"
                      :rules="[]"
                      :multiple="true"
                      @updateStore="$event => (brand.values = $event)"
                      @getOptions="onGetBrandOptions"
                      tabindex="22"
                      rawData
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="add"
                  round
                  @click="addBrand2"
                  v-if="!enabledBrand2"
                  tabindex="23"
                >
                  <Tooltip text="Add another Brand filter" />
                </Button>
                <Button
                  variant="primary"
                  icon="remove"
                  round
                  @click="removeBrand1"
                  v-if="enabledBrand1 && enabledBrand2"
                  tabindex="24"
                >
                  <Tooltip text="Remove this Brand filter" />
                </Button>
              </div>
            </div>

            <div
              class="row items-start q-col-gutter-md"
              v-if="enabledBrand2 && isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Brand</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="Bought/Did Not Buy"
                      v-model="brand2.bought"
                      :values="brand2.bought"
                      :options="boughtOptions"
                      :rules="[]"
                      tabindex="25"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <SelectBrands
                      class="max-w-xl mb-2"
                      v-model="brand2.values"
                      option-value="value"
                      option-label="label"
                      :values="brand2.values"
                      :disabled="brand2.bought === null"
                      :rules="[]"
                      :multiple="true"
                      @updateStore="$event => (brand2.values = $event)"
                      @getOptions="onGetBrandOptions2"
                      tabindex="26"
                      rawData
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="add"
                  round
                  @click="addBrand1"
                  v-if="!enabledBrand1"
                  tabindex="27"
                >
                  <Tooltip text="Add another Brand filter" />
                </Button>
                <Button
                  variant="primary"
                  icon="remove"
                  round
                  @click="removeBrand2"
                  v-if="enabledBrand1 && enabledBrand2"
                  tabindex="28"
                >
                  <Tooltip text="Remove this Brand filter" />
                </Button>
              </div>
            </div>

            <div
              class="row items-start q-col-gutter-md"
              v-if="enabledCategory1 && isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Category</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="Bought/Did Not Buy"
                      v-model="category.bought"
                      :values="category.bought"
                      :options="boughtOptions"
                      :rules="[]"
                      tabindex="29"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <SelectCategories
                      class="max-w-xl mb-2"
                      v-model="category.values"
                      option-value="value"
                      option-label="label"
                      :values="category.values"
                      :disabled="category.bought === null"
                      :rules="[]"
                      :multiple="true"
                      @updateStore="$event => (category.values = $event)"
                      @getOptions="onGetCategoryOptions"
                      tabindex="30"
                      rawData
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="add"
                  round
                  @click="addCategory2"
                  v-if="!enabledCategory2"
                  tabindex="31"
                >
                  <Tooltip text="Add another Category filter" />
                </Button>
                <Button
                  variant="primary"
                  icon="remove"
                  round
                  @click="removeCategory1"
                  v-if="enabledCategory1 && enabledCategory2"
                  tabindex="32"
                >
                  <Tooltip text="Remove this Category filter" />
                </Button>
              </div>
            </div>

            <div
              class="row items-start q-col-gutter-md"
              v-if="enabledCategory2 && isMyCustomers"
            >
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Category</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="Bought/Did Not Buy"
                      v-model="category2.bought"
                      :values="category2.bought"
                      :options="boughtOptions"
                      :rules="[]"
                      tabindex="33"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <SelectCategories
                      class="max-w-xl mb-2"
                      v-model="category2.values"
                      option-value="value"
                      option-label="label"
                      :values="category2.values"
                      :disabled="category2.bought === null"
                      :rules="[]"
                      :multiple="true"
                      @updateStore="$event => (category2.values = $event)"
                      @getOptions="onGetCategoryOptions2"
                      tabindex="34"
                      rawData
                    />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-1 col-md-1">
                <Button
                  variant="primary"
                  icon="add"
                  round
                  @click="addCategory1"
                  v-if="!enabledCategory1"
                  tabindex="35"
                >
                  <Tooltip text="Add another Category filter" />
                </Button>
                <Button
                  variant="primary"
                  icon="remove"
                  round
                  @click="removeCategory2"
                  v-if="enabledCategory1 && enabledCategory2"
                  tabindex="36"
                >
                  <Tooltip text="Remove this Category filter" />
                </Button>
              </div>
            </div>

            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Ave. Basket Size</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="At Least/At Most/In Between"
                      v-model="basket.leastMost"
                      :values="basket.leastMost"
                      :options="basketOptions"
                      :rules="[]"
                      tabindex="37"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="flex gap-2">
                      <FormInput
                        :label="
                          basket.leastMost === 'in-between' ? 'Php From' : 'Php'
                        "
                        v-model.number="basket.value"
                        mask="#"
                        fill-mask="0"
                        :rules="[]"
                        :disabled="basket.leastMost === null"
                        tabindex="38"
                        outlined
                        reverse-fill-mask
                      />
                      <FormInput
                        :label="
                          basket.leastMost === 'in-between' ? 'Php To' : 'Php'
                        "
                        v-model.number="basket.value2"
                        mask="#"
                        fill-mask="0"
                        :rules="[]"
                        :disabled="basket.leastMost === null"
                        tabindex="39"
                        outlined
                        reverse-fill-mask
                        v-if="basket.leastMost === 'in-between'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-3 col-md-3 labelContainer">
                <label class="labelText">Order Count</label>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-8">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <FormSelect
                      label="At Least/At Most/In Between"
                      v-model="order.leastMost"
                      :values="order.leastMost"
                      :options="orderOptions"
                      :rules="[]"
                      tabindex="40"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="flex gap-2">
                      <FormInput
                        :label="
                          order.leastMost === 'in-between'
                            ? 'Count From'
                            : 'Count'
                        "
                        v-model.number="order.value"
                        mask="#"
                        fill-mask="0"
                        :rules="[]"
                        :disabled="order.leastMost === null"
                        tabindex="41"
                        outlined
                        reverse-fill-mask
                      />
                      <FormInput
                        :label="
                          order.leastMost === 'in-between'
                            ? 'Count To'
                            : 'Count'
                        "
                        v-model.number="order.value2"
                        mask="#"
                        fill-mask="0"
                        :rules="[]"
                        :disabled="order.leastMost === null"
                        tabindex="42"
                        outlined
                        reverse-fill-mask
                        v-if="order.leastMost === 'in-between'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SegmentFormContainer>
        </q-step>

        <q-step
          class="stepHeight"
          :name="4"
          title="Estimate"
          icon="add_comment"
          :done="segmentStep > 4"
        >
          <div class="row items-start q-col-gutter-sm">
            <div class="col-xs-12 col-sm-12 col-md-4 lt-md">
              <div class="column q-col-gutter-sm q-ma-md">
                <SimpleCard variant="primary">
                  <p class="text-xs font-semibold">
                    ESTIMATED MEMBERS IN THIS SEGMENT
                  </p>
                  <h2>{{ estimatedData }}</h2>
                  <Loader
                    :loading="loadingEstimate"
                    v-if="loadingEstimate"
                    inner
                  />
                </SimpleCard>

                <Button
                  class="mt-4"
                  variant="primary"
                  label="Estimate"
                  size="lg"
                  outline
                  :loading="isLoading"
                  :disabled="isLoading"
                  @click="onSubmitEstimate"
                  @mousedown="toggleEstimate"
                  v-if="segmentStep === segmentStepEnd"
                >
                  <template v-slot:icon>
                    <q-icon name="swap_horizontal_circle" size="md" />
                  </template>
                </Button>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-8">
              <div class="column q-col-gutter-sm q-ma-md">
                <q-list bordered separator style="min-width: 600px">
                  <q-item>
                    <q-item-section class="listHeader">
                      Segment Code
                    </q-item-section>
                    <q-item-section>{{ segmentCode ?? '-' }}</q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section class="listHeader">
                      Segment Name
                    </q-item-section>
                    <q-item-section>{{ segmentName ?? '-' }}</q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section class="listHeader">Remarks</q-item-section>
                    <q-item-section>{{ segmentRemarks ?? '-' }}</q-item-section>
                  </q-item>

                  <q-item>
                    <q-item-section class="listHeader">
                      Customer Type
                    </q-item-section>
                    <q-item-section>
                      {{ getCustomerTypeName(customerType) }}
                    </q-item-section>
                  </q-item>

                  <q-item v-if="customerType && !isMyCustomers">
                    <q-item-section class="listHeader">
                      Purchase Month
                    </q-item-section>
                    <q-item-section>
                      {{ customerDateFrom }} to
                      {{ customerDateTo }}
                    </q-item-section>
                  </q-item>

                  <q-item v-if="isMyCustomers">
                    <q-item-section class="listHeader">
                      Purchase Time Frame
                    </q-item-section>
                    <q-item-section>
                      {{ DATE.toFriendlyDate(date.dateFrom) }} to
                      {{ DATE.toFriendlyDate(date.dateTo) }}
                    </q-item-section>
                  </q-item>

                  <q-item v-if="enabledTime2 && isMyCustomers">
                    <q-item-section class="listHeader">
                      Did Not Buy Time Frame
                    </q-item-section>
                    <q-item-section>
                      {{ DATE.toFriendlyDate(date2.dateFrom) }} to
                      {{ DATE.toFriendlyDate(date2.dateTo) }}
                    </q-item-section>
                  </q-item>

                  <q-item v-if="isMyCustomers">
                    <q-item-section class="listHeader"> Stores </q-item-section>
                    <q-item-section>
                      <div>
                        <q-item-label class="q-mb-sm font-semibold">
                          {{ store.values.length }} Stores
                        </q-item-label>
                        <Badge
                          class="q-mb-sm q-mr-sm"
                          v-for="item in store.values"
                          variant="default"
                          :key="item.value"
                          :text="item.label"
                        />
                      </div>
                    </q-item-section>
                  </q-item>

                  <q-item
                    v-if="
                      isMyCustomers &&
                      ((product.bought !== null && product.values.length > 0) ||
                        (product2.bought !== null &&
                          product2.values.length > 0))
                    "
                  >
                    <q-item-section class="listHeader">
                      Product
                    </q-item-section>

                    <q-item-section>
                      <div v-if="enabledProduct1">
                        <q-item-label class="q-mb-sm font-semibold">
                          {{ getBoughtName(product.bought) }}
                        </q-item-label>
                        <Badge
                          class="q-mb-sm q-mr-sm"
                          v-for="item in product.values"
                          variant="default"
                          :key="item.value"
                          :text="`${item.value} - ${item.label}`"
                        />
                      </div>
                      <q-separator
                        class="q-my-md"
                        v-if="enabledProduct1 && enabledProduct2"
                      />
                      <div v-if="enabledProduct2">
                        <q-item-label class="q-mb-sm font-semibold">
                          {{ getBoughtName(product2.bought) }}
                        </q-item-label>
                        <Badge
                          class="q-mb-sm q-mr-sm"
                          v-for="item in product2.values"
                          variant="default"
                          :key="item.value"
                          :text="`${item.value} - ${item.label}`"
                        />
                      </div>
                    </q-item-section>
                  </q-item>

                  <q-item
                    v-if="
                      isMyCustomers &&
                      ((brand.bought !== null && brand.values.length > 0) ||
                        (brand2.bought !== null && brand2.values.length > 0))
                    "
                  >
                    <q-item-section class="listHeader"> Brand </q-item-section>

                    <q-item-section>
                      <div v-if="enabledBrand1">
                        <q-item-label class="q-mb-sm font-semibold">
                          {{ getBoughtName(brand.bought) }}
                        </q-item-label>
                        <Badge
                          class="q-mb-sm q-mr-sm"
                          v-for="item in brand.values"
                          variant="default"
                          :key="item.value"
                          :text="item.label"
                        />
                      </div>
                      <q-separator
                        class="q-my-md"
                        v-if="enabledBrand1 && enabledBrand2"
                      />
                      <div v-if="enabledBrand2">
                        <q-item-label class="q-mb-sm font-semibold">
                          {{ getBoughtName(brand2.bought) }}
                        </q-item-label>
                        <Badge
                          class="q-mb-sm q-mr-sm"
                          v-for="item in brand2.values"
                          variant="default"
                          :key="item.value"
                          :text="item.label"
                        />
                      </div>
                    </q-item-section>
                  </q-item>

                  <q-item
                    v-if="
                      isMyCustomers &&
                      ((category.bought !== null &&
                        category.values.length > 0) ||
                        (category2.bought !== null &&
                          category2.values.length > 0))
                    "
                  >
                    <q-item-section class="listHeader">
                      Category
                    </q-item-section>

                    <q-item-section>
                      <div v-if="enabledCategory1">
                        <q-item-label class="q-mb-sm font-semibold">
                          {{ getBoughtName(category.bought) }}
                        </q-item-label>
                        <Badge
                          class="q-mb-sm q-mr-sm"
                          v-for="item in category.values"
                          variant="default"
                          :key="item.value"
                          :text="item.label"
                        />
                      </div>
                      <q-separator
                        class="q-my-md"
                        v-if="enabledCategory1 && enabledCategory2"
                      />
                      <div v-if="enabledCategory2">
                        <q-item-label class="q-mb-sm font-semibold">
                          {{ getBoughtName(category2.bought) }}
                        </q-item-label>
                        <Badge
                          class="q-mb-sm q-mr-sm"
                          v-for="item in category2.values"
                          variant="default"
                          :key="item.value"
                          :text="item.label"
                        />
                      </div>
                    </q-item-section>
                  </q-item>

                  <q-item v-if="basket.leastMost">
                    <q-item-section class="listHeader">
                      Basket Size
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="q-mb-sm">
                        {{ getLeastMostName(basket.leastMost) }}
                      </q-item-label>
                      <div>
                        <Badge
                          variant="default"
                          :text="
                            basket.leastMost === 'in-between'
                              ? `${basket.value} to ${basket.value2}`
                              : basket.value
                          "
                        />
                      </div>
                    </q-item-section>
                  </q-item>

                  <q-item v-if="order.leastMost">
                    <q-item-section class="listHeader">
                      Order Count
                    </q-item-section>
                    <q-item-section>
                      <q-item-label class="q-mb-sm">
                        {{ getLeastMostName(order.leastMost) }}
                      </q-item-label>
                      <div>
                        <Badge
                          variant="default"
                          :text="
                            order.leastMost === 'in-between'
                              ? `${order.value} to ${order.value2}`
                              : order.value
                          "
                        />
                      </div>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-4 gt-md">
              <div class="column q-col-gutter-sm q-ma-md">
                <SimpleCard variant="primary">
                  <p class="text-xs font-semibold">
                    ESTIMATED MEMBERS IN THIS SEGMENT
                  </p>
                  <h2>{{ estimatedData }}</h2>
                  <Loader
                    :loading="loadingEstimate"
                    v-if="loadingEstimate"
                    inner
                  />
                </SimpleCard>

                <Button
                  class="mt-4"
                  variant="primary"
                  label="Estimate"
                  size="lg"
                  outline
                  :loading="isLoading"
                  :disabled="isLoading"
                  @click="onSubmitEstimate"
                  @mousedown="toggleEstimate"
                  v-if="segmentStep === segmentStepEnd"
                >
                  <template v-slot:icon>
                    <q-icon name="swap_horizontal_circle" size="md" />
                  </template>
                </Button>
              </div>
            </div>
          </div>
        </q-step>

        <template v-slot:navigation>
          <SegmentStepperButton
            :step="segmentStep"
            :stepEnd="segmentStepEnd"
            :loading="isLoading"
            @next="onStepNext()"
            @previous="onStepPrevious()"
            @skip="onStepSkip()"
            @estimate="onSubmitEstimate()"
          />
        </template>

        <template v-slot:message>
          <SegmentStepperBanner
            text="Create a segment by giving it a code, name and remarks."
            v-if="segmentStep === 1"
          />
          <SegmentStepperBanner
            text="Select a customer type (My Customers / Non-Customers)."
            v-else-if="segmentStep === 2"
          />
          <SegmentStepperBanner
            text="Select filters for your segment."
            v-else-if="segmentStep === 3"
          />
          <SegmentStepperBanner
            text="Estimate member count according to your filters."
            v-else-if="segmentStep === 4"
          >
            <div class="flex items-center gap-2">
              <q-icon name="info" size="xs" />
              Click on Save Segment to finalize this segment.
            </div>
          </SegmentStepperBanner>
        </template>
      </q-stepper>
    </q-form>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">Create Customer Segment</h5>

      <q-separator class="q-my-md" />

      <div class="q-mb-md">
        <h6 class="text-sm mb-2">
          {{ HELP.CUSTOMER_SEGMENT.create.description }}
        </h6>
        <ol>
          <li v-for="list in HELP.CUSTOMER_SEGMENT.create.lists" :key="list.id">
            {{ list.text }}
          </li>
        </ol>
      </div>
    </div>
  </Modal>

  <ConfirmCustomerSegmentModal
    :show="showConfirmModal"
    :loading="loadingCreate"
    @close="onCloseConfirmModal"
    @submit="onSubmitCreate"
  >
    <p>Estimated members is zero (0).</p>
    <p>Are you sure you want to continue saving this segment?</p>
  </ConfirmCustomerSegmentModal>
</template>

<script>
import { ref, onBeforeMount, watch, inject, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Button'
import { FormInput, FormDate, FormSelect } from '@/components/inputs'
import { SimpleCard } from '@/components/cards'
import Modal from '@/components/Modal'
import Tag from '@/components/Tag'
import Loader from '@/components/Loader'
import Tooltip from '@/components/Tooltip'
import Notif from '@/components/Notif'
import Badge from '@/components/Badge'

import {
  SelectStores,
  SelectProducts,
  SelectBrands,
  SelectCategories
} from '@/components/customs'

import { FORMAT, VALIDATION, DATE, getIconUrl, Toast, fetchData } from '@/tools'
import {
  HELP,
  optionsBought,
  optionsLeastMost,
  optionsMyNonCustomers
} from '@/constants'

import SegmentFormContainer from './components/SegmentFormContainer'
import SegmentStepperBanner from './components/SegmentStepperBanner'
import SegmentStepperButton from './components/SegmentStepperButton'
import ConfirmCustomerSegmentModal from './components/ConfirmCustomerSegmentModal'

export default {
  name: 'CustomerSegmentCreate',
  components: {
    PageHeader,
    PageContent,
    Button,
    FormInput,
    FormDate,
    FormSelect,
    SimpleCard,
    Modal,
    Tag,
    Loader,
    Tooltip,
    Notif,
    Badge,
    SelectStores,
    SelectProducts,
    SelectBrands,
    SelectCategories,
    SegmentFormContainer,
    SegmentStepperBanner,
    SegmentStepperButton,
    ConfirmCustomerSegmentModal
  },
  setup() {
    const $q = useQuasar()
    const router = useRouter()
    const { showToast } = Toast()
    const state = inject('store')?.state

    const segmentID = router?.currentRoute?.value?.params?.id
    const isUpdatePage =
      router?.currentRoute?.value?.name === 'customer_segment_update'

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const {
      data: dataEstimate,
      error: errorEstimate,
      loading: loadingEstimate,
      post: postEstimate
    } = fetchData()

    const {
      data: dataCreate,
      error: errorCreate,
      loading: loadingCreate,
      post: postCreate
    } = fetchData()

    const {
      data: dataUpdate,
      error: errorUpdate,
      loading: loadingUpdate,
      put: postUpdate
    } = fetchData()

    const {
      data: dataProduct,
      error: errorProduct,
      loading: loadingProduct,
      post: postProduct
    } = fetchData()

    const {
      data: dataProduct2,
      error: errorProduct2,
      loading: loadingProduct2,
      post: postProduct2
    } = fetchData()

    const isLoading = computed(() => {
      return loadingEstimate.value
    })
    const maxDate = computed(() => {
      return DATE.toFriendlyDate(
        DATE.addDays(date.value.dateFrom, 6, 'months'),
        '/'
      )
    })
    const minDate = computed(() => {
      return DATE.toFriendlyDate(date.value.dateFrom, '/')
    })
    const maxDate2 = computed(() => {
      return DATE.toFriendlyDate(
        DATE.addDays(date2.value.dateFrom, 6, 'months'),
        '/'
      )
    })
    const minDate2 = computed(() => {
      return DATE.toFriendlyDate(date2.value.dateFrom, '/')
    })
    const requiredField = computed(() => {
      return isEstimate.value ? [] : [VALIDATION.required]
    })
    const isMyCustomers = computed(() => {
      return customerType.value === optionsMyNonCustomers[0].value
    })
    const notif = ref(null)
    const showModal = ref(false)
    const showConfirmModal = ref(false)
    const filterForm = ref(null)
    const stepper = ref(null)
    const isEstimate = ref(false)
    const isUpdateAllowed = ref(false)
    const segmentStep = ref(1)
    const segmentStepEnd = ref(4)
    const segmentData = ref(null)
    const estimatedData = ref(0)
    const segmentCode = ref(null)
    const segmentName = ref(null)
    const segmentRemarks = ref(null)
    const customerType = ref(null)
    const customerDateFrom = ref(
      DATE.toFriendlyMonthYear(DATE.subtractDays(new Date(), 1, 'months'), '/')
    )
    const customerDateTo = ref(
      DATE.toFriendlyMonthYear(DATE.subtractDays(new Date(), 1, 'months'), '/')
    )
    const customerOptions = ref(optionsMyNonCustomers)
    const storeOptions = ref(optionsBought)
    const boughtOptions = ref(optionsBought)
    const basketOptions = ref(optionsLeastMost)
    const orderOptions = ref(optionsLeastMost)
    const enabledTime2 = ref(false)
    const enabledProduct1 = ref(true)
    const enabledProduct2 = ref(false)
    const enabledBrand1 = ref(true)
    const enabledBrand2 = ref(false)
    const enabledCategory1 = ref(true)
    const enabledCategory2 = ref(false)
    const date = ref({
      dateFrom: null,
      dateTo: null
    })
    const date2 = ref({
      bought: null,
      dateFrom: null,
      dateTo: null
    })
    const store = ref({
      value: null,
      values: []
    })
    const product = ref({
      bought: null,
      value: null,
      values: []
    })
    const product2 = ref({
      bought: null,
      value: null,
      values: []
    })
    const brand = ref({
      bought: null,
      value: null,
      values: []
    })
    const brand2 = ref({
      bought: null,
      value: null,
      values: []
    })
    const category = ref({
      bought: null,
      value: null,
      values: []
    })
    const category2 = ref({
      bought: null,
      value: null,
      values: []
    })
    const basket = ref({
      leastMost: null,
      value: 0,
      value2: 0
    })
    const order = ref({
      leastMost: null,
      value: 0,
      value2: 0
    })

    const goToListsPage = () => {
      router.push({ name: 'customer_segment_list' })
    }

    const onStepNext = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          stepper.value.next()
        }
      })
    }

    const onStepPrevious = () => {
      stepper.value.previous()
    }

    const onStepSkip = () => {
      stepper.value.next()
    }

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onToggleConfirmModal = () => {
      showConfirmModal.value = !showConfirmModal.value
    }

    const onCloseConfirmModal = () => {
      showConfirmModal.value = false
    }

    const onSelectCustomerDateFrom = data => {
      customerDateFrom.value = data
    }

    const onSelectCustomerDateTo = data => {
      customerDateTo.value = data
    }

    const onSelectFromDate = data => {
      date.value.dateFrom = data
      date.value.dateTo = null
    }

    const onSelectToDate = data => {
      date.value.dateTo = data
    }

    const onSelectFromDate2 = data => {
      date2.value.dateFrom = data
      date2.value.dateTo = null
    }

    const onSelectToDate2 = data => {
      date2.value.dateTo = data
    }

    const onGetStoreOptions = data => {
      if (isUpdatePage && store.value.value) {
        store.value.values = data.filter(item =>
          store.value.value.includes(item.value)
        )
      }
    }

    const onGetBrandOptions = data => {
      if (isUpdatePage && brand.value.value) {
        brand.value.values = data.filter(item =>
          brand.value.value.includes(item.value)
        )
      }
    }

    const onGetBrandOptions2 = data => {
      if (isUpdatePage && brand2.value.value) {
        brand2.value.values = data.filter(item =>
          brand2.value.value.includes(item.value)
        )
      }
    }

    const onGetCategoryOptions = data => {
      if (isUpdatePage && category.value.value) {
        category.value.values = data.filter(item =>
          category.value.value.includes(item.value)
        )
      }
    }

    const onGetCategoryOptions2 = data => {
      if (isUpdatePage && category2.value.value) {
        category2.value.values = data.filter(item =>
          category2.value.value.includes(item.value)
        )
      }
    }

    const onSelectSKU = data => {
      if (data && product.value.values) {
        const exists = Object.values(product.value.values).includes(data)

        if (!exists && data && data.length !== 0) {
          product.value.values = [...product.value.values, data]
        }
      }
      product.value.value = null
    }

    const onRemoveSKU = data => {
      product.value.values = product.value.values.filter(
        item => item.value !== data
      )
    }

    const onSelectSKU2 = data => {
      if (data && product2.value.values) {
        const exists = Object.values(product2.value.values).includes(data)

        if (!exists && data && data.length !== 0) {
          product2.value.values = [...product2.value.values, data]
        }
      }
      product2.value.value = null
    }

    const onRemoveSKU2 = data => {
      product2.value.values = product2.value.values.filter(
        item => item.value !== data
      )
    }

    const onSubmitEstimate = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          let isError = false
          const payload = {
            filters: {
              retailer: state?.profile?.retailer_id
            }
          }

          if (!isMyCustomers.value) {
            payload.filters.month = {
              from: DATE.toFriendlyDate(
                DATE.toFriendlyFirstDay(customerDateFrom.value)
              ),
              to: DATE.toFriendlyDate(
                DATE.toFriendlyFirstDay(customerDateTo.value)
              )
            }
          }

          if (isMyCustomers.value) {
            if (date.value.dateFrom && date.value.dateTo) {
              payload.filters.date = {
                from: date.value.dateFrom,
                to: date.value.dateTo
              }
            }

            if (store.value.values) {
              payload.filters.stores = store.value.values.map(
                item => item.value
              )
            }

            if (date2.value.bought !== null) {
              if (date2.value.bought === false) {
                payload.filters.not_date = {
                  from: date2.value.dateFrom,
                  to: date2.value.dateTo
                }
              }
            }

            if (
              product.value.bought !== null &&
              (product.value.values?.length > 0 ||
                product2.value.values?.length > 0)
            ) {
              payload.filters.product = []

              if (product.value.values?.length > 0) {
                payload.filters.product.push({
                  bought: JSON.parse(product.value.bought),
                  sku_id: product.value.values.map(item => item.value)
                })
              }

              if (product2.value.values?.length > 0) {
                payload.filters.product.push({
                  bought: JSON.parse(product2.value.bought),
                  sku_id: product2.value.values.map(item => item.value)
                })
              }
            }

            if (
              brand.value.bought !== null &&
              (brand.value.values || brand2.value.values)
            ) {
              payload.filters.brand = []

              if (brand.value.values?.length > 0) {
                payload.filters.brand.push({
                  bought: JSON.parse(brand.value.bought),
                  brand_id: brand.value.values.map(item => item.value)
                })
              }

              if (brand2.value.values?.length > 0) {
                payload.filters.brand.push({
                  bought: JSON.parse(brand2.value.bought),
                  brand_id: brand2.value.values.map(item => item.value)
                })
              }
            }

            if (
              category.value.bought !== null &&
              (category.value.values || category2.value.values)
            ) {
              payload.filters.category = []

              if (category.value.values?.length > 0) {
                payload.filters.category.push({
                  bought: JSON.parse(category.value.bought),
                  category_id: category.value.values.map(item => item.value)
                })
              }

              if (category2.value.values?.length > 0) {
                payload.filters.category.push({
                  bought: JSON.parse(category2.value.bought),
                  category_id: category2.value.values.map(item => item.value)
                })
              }
            }
          }

          if (basket.value.leastMost) {
            payload.filters.basket_size = {
              option: basket.value.leastMost,
              value: basket.value.value
            }

            if (basket.value.leastMost === 'in-between') {
              payload.filters.basket_size = {
                ...payload.filters.basket_size,
                value2: basket.value.value2
              }
            }
          }

          if (order.value.leastMost) {
            payload.filters.order_count = {
              option: order.value.leastMost,
              value: order.value.value
            }

            if (order.value.leastMost === 'in-between') {
              payload.filters.order_count = {
                ...payload.filters.order_count,
                value2: order.value.value2
              }
            }
          }

          if (
            basket.value.leastMost &&
            basket.value.leastMost === 'in-between' &&
            basket.value.value2 <= basket.value.value
          ) {
            isError = true
            showToast('Php To must be greater than Php From', 'info')
          }

          if (
            order.value.leastMost &&
            order.value.leastMost === 'in-between' &&
            order.value.value2 <= order.value.value
          ) {
            isError = true
            showToast('Count To must be greater than Count From', 'info')
          }

          if (!isError) {
            if (isMyCustomers.value) {
              postEstimate(
                '/v1/customer-segmentation/filtered/execute-customers',
                payload
              )
            } else {
              postEstimate(
                '/v1/customer-segmentation/filtered/execute-non-customers',
                payload
              )
            }
          }
        }
      })
    }

    const onValidateSubmit = () => {
      if (estimatedData.value === 0) {
        onToggleConfirmModal()
      } else {
        onSubmitCreate()
      }
    }

    const onSubmitCreate = () => {
      onCloseConfirmModal()

      filterForm.value.validate().then(success => {
        if (success) {
          if (
            !segmentCode.value ||
            !segmentName.value ||
            !segmentRemarks.value
          ) {
            if (!segmentCode.value) {
              segmentStep.value = 1
              showToast('Segment code is required', 'info')
            }

            if (!segmentName.value) {
              segmentStep.value = 1
              showToast('Segment name is required', 'info')
            }

            if (!segmentRemarks.value) {
              segmentStep.value = 1
              showToast('Segment remarks is required', 'info')
            }

            return
          }

          let isError = false
          const payload = {
            segmentation: {
              code: segmentCode.value,
              name: segmentName.value,
              remarks: segmentRemarks.value
            },
            filters: {
              retailer: state?.profile?.retailer_id
            }
          }

          if (!isMyCustomers.value) {
            payload.filters.month = {
              from: DATE.toFriendlyDate(
                DATE.toFriendlyFirstDay(customerDateFrom.value)
              ),
              to: DATE.toFriendlyDate(
                DATE.toFriendlyFirstDay(customerDateTo.value)
              )
            }
          }

          if (isMyCustomers.value) {
            if (date.value.dateFrom && date.value.dateTo) {
              payload.filters.date = {
                from: date.value.dateFrom,
                to: date.value.dateTo
              }
            }

            if (store.value.values) {
              payload.filters.stores = store.value.values.map(
                item => item.value
              )
            }

            if (date2.value.bought !== null) {
              if (date2.value.bought === true) {
                payload.filters.date = {
                  from: date2.value.dateFrom,
                  to: date2.value.dateTo
                }
              } else if (date2.value.bought === false) {
                payload.filters.not_date = {
                  from: date2.value.dateFrom,
                  to: date2.value.dateTo
                }
              }
            }

            if (
              product.value.bought !== null &&
              (product.value.values?.length > 0 ||
                product2.value.values?.length > 0)
            ) {
              payload.filters.product = []

              if (product.value.values?.length > 0) {
                payload.filters.product.push({
                  bought: JSON.parse(product.value.bought),
                  sku_id: product.value.values.map(item => item.value)
                })
              }

              if (product2.value.values?.length > 0) {
                payload.filters.product.push({
                  bought: JSON.parse(product2.value.bought),
                  sku_id: product2.value.values.map(item => item.value)
                })
              }
            }

            if (
              brand.value.bought !== null &&
              (brand.value.values || brand2.value.values)
            ) {
              payload.filters.brand = []

              if (brand.value.values?.length > 0) {
                payload.filters.brand.push({
                  bought: JSON.parse(brand.value.bought),
                  brand_id: brand.value.values.map(item => item.value)
                })
              }

              if (brand2.value.values?.length > 0) {
                payload.filters.brand.push({
                  bought: JSON.parse(brand2.value.bought),
                  brand_id: brand2.value.values.map(item => item.value)
                })
              }
            }

            if (
              category.value.bought !== null &&
              (category.value.values || category2.value.values)
            ) {
              payload.filters.category = []

              if (category.value.values?.length > 0) {
                payload.filters.category.push({
                  bought: JSON.parse(category.value.bought),
                  category_id: category.value.values.map(item => item.value)
                })
              }

              if (category2.value.values?.length > 0) {
                payload.filters.category.push({
                  bought: JSON.parse(category2.value.bought),
                  category_id: category2.value.values.map(item => item.value)
                })
              }
            }
          }

          if (basket.value.leastMost) {
            payload.filters.basket_size = {
              option: basket.value.leastMost,
              value: basket.value.value
            }

            if (basket.value.leastMost === 'in-between') {
              payload.filters.basket_size = {
                ...payload.filters.basket_size,
                value2: basket.value.value2
              }
            }
          }

          if (order.value.leastMost) {
            payload.filters.order_count = {
              option: order.value.leastMost,
              value: order.value.value
            }

            if (order.value.leastMost === 'in-between') {
              payload.filters.order_count = {
                ...payload.filters.order_count,
                value2: order.value.value2
              }
            }
          }

          if (
            basket.value.leastMost &&
            basket.value.leastMost === 'in-between' &&
            basket.value.value2 <= basket.value.value
          ) {
            isError = true
            showToast('Php To must be greater than Php From', 'info')
          }

          if (
            order.value.leastMost &&
            order.value.leastMost === 'in-between' &&
            order.value.value2 <= order.value.value
          ) {
            isError = true
            showToast('Count To must be greater than Count From', 'info')
          }

          if (!isError) {
            if (isUpdatePage) {
              if (isMyCustomers.value) {
                postUpdate(
                  `/v1/customer-segmentation/filtered/customers/${segmentID}`,
                  payload
                )
              } else {
                postUpdate(
                  `/v1/customer-segmentation/filtered/non-customers/${segmentID}`,
                  payload
                )
              }
            } else {
              if (isMyCustomers.value) {
                postCreate(
                  '/v1/customer-segmentation/filtered/customers',
                  payload
                )
              } else {
                postCreate(
                  '/v1/customer-segmentation/filtered/non-customers',
                  payload
                )
              }
            }
          }
        }
      })
    }

    const onFetchSKUs = data => {
      const payload = {
        sku_id: data
      }
      postProduct('/v1/products/by-sku-id', payload, true)
    }

    const onFetchSKUs2 = data => {
      const payload = {
        sku_id: data
      }
      postProduct2('/v1/products/by-sku-id', payload, true)
    }

    const addTime2 = () => {
      enabledTime2.value = true
      date2.value.bought = false
    }

    const removeTime2 = () => {
      enabledTime2.value = false
      date2.value = {
        bought: null,
        dateFrom: null,
        dateTo: null
      }
    }

    const addProduct1 = () => {
      enabledProduct1.value = true
    }

    const removeProduct1 = () => {
      enabledProduct1.value = false
      product.value = {
        bought: null,
        value: null,
        values: []
      }
    }

    const addProduct2 = () => {
      enabledProduct2.value = true
    }

    const removeProduct2 = () => {
      enabledProduct2.value = false
      product2.value = {
        bought: null,
        value: null,
        values: []
      }
    }

    const addBrand1 = () => {
      enabledBrand1.value = true
    }

    const removeBrand1 = () => {
      enabledBrand1.value = false
      brand.value = {
        bought: null,
        values: null
      }
    }

    const addBrand2 = () => {
      enabledBrand2.value = true
    }

    const removeBrand2 = () => {
      enabledBrand2.value = false
      brand2.value = {
        bought: null,
        values: null
      }
    }

    const addCategory1 = () => {
      enabledCategory1.value = true
    }

    const removeCategory1 = () => {
      enabledCategory1.value = false
      category.value = {
        bought: null,
        values: null
      }
    }

    const addCategory2 = () => {
      enabledCategory2.value = true
    }

    const removeCategory2 = () => {
      enabledCategory2.value = false
      category2.value = {
        bought: null,
        values: null
      }
    }

    const toggleEstimate = () => {
      isEstimate.value = true
    }

    const toggleCreate = () => {
      isEstimate.value = false
    }

    const getCustomerTypeName = data => {
      return customerOptions.value.find(item => item.value === data)?.label
    }

    const getBoughtName = data => {
      return boughtOptions.value.filter(item => item.value === data)[0]?.label
    }

    const getLeastMostName = data => {
      return basketOptions.value.filter(item => item.value === data)[0]?.label
    }

    const getCustomerSegment = id => {
      getLists(`/v1/customer-segmentation/filtered/${id}`)
    }

    onBeforeMount(() => {
      if (isUpdatePage && segmentID) {
        getCustomerSegment(segmentID)
      }

      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          segmentData.value = null
        } else {
          if (errorLists?.value) {
            showToast(
              errorLists?.value?.message ??
                'There was a problem fetching customer segment.',
              'danger'
            )
          } else if (dataLists?.value) {
            const data = dataLists?.value
            const filters = JSON.parse(data?.filters)

            isUpdateAllowed.value =
              data?.campaign_tag !== 'Y' && data?.status === 'A'

            if (isUpdateAllowed.value) {
              segmentData.value = {
                id: data?.id,
                segmentCode: data?.code,
                segmentName: data?.name
              }

              segmentCode.value = data?.code
              segmentName.value = data?.name
              segmentRemarks.value = data?.remarks
              estimatedData.value = data?.customer_count
              customerType.value = data?.customer_flag

              if (customerType.value === 'F') {
                customerDateFrom.value = DATE.toFriendlyMonthYear(
                  filters?.month?.from,
                  '/'
                )
                customerDateTo.value = DATE.toFriendlyMonthYear(
                  filters?.month?.to,
                  '/'
                )
              } else {
                date.value = {
                  dateFrom: filters?.date?.from,
                  dateTo: filters?.date?.to
                }

                if (filters?.not_date) {
                  enabledTime2.value = true
                  date2.value = {
                    bought: false,
                    dateFrom: filters?.not_date?.from,
                    dateTo: filters?.not_date?.to
                  }
                }

                store.value = {
                  value: filters?.stores,
                  values: []
                }

                if (filters?.product?.length > 0) {
                  if (filters?.product[0]) {
                    onFetchSKUs(filters?.product[0]?.sku_id)

                    product.value = {
                      bought: filters?.product[0]?.bought,
                      value: null
                    }
                  }

                  if (filters?.product[1]) {
                    enabledProduct2.value = true
                    onFetchSKUs2(filters?.product[1]?.sku_id)

                    product2.value = {
                      bought: filters?.product[1]?.bought,
                      value: null
                    }
                  }
                }

                if (filters?.brand?.length > 0) {
                  if (filters?.brand[0]) {
                    brand.value = {
                      bought: filters?.brand[0]?.bought,
                      value: filters?.brand[0]?.brand_id,
                      values: []
                    }
                  }

                  if (filters?.brand[1]) {
                    enabledBrand2.value = true
                    brand2.value = {
                      bought: filters?.brand[1]?.bought,
                      value: filters?.brand[1]?.brand_id,
                      values: []
                    }
                  }
                }

                if (filters?.category?.length > 0) {
                  if (filters?.category[0]) {
                    category.value = {
                      bought: filters?.category[0]?.bought,
                      value: filters?.category[0]?.category_id,
                      values: []
                    }
                  }

                  if (filters?.category[1]) {
                    enabledCategory2.value = true
                    category2.value = {
                      bought: filters?.category[1]?.bought,
                      value: filters?.category[1]?.category_id,
                      values: []
                    }
                  }
                }
              }

              if (filters?.basket_size) {
                basket.value = {
                  leastMost: filters?.basket_size?.option,
                  value: filters?.basket_size?.value,
                  value2: filters?.basket_size?.value2
                }
              }

              if (filters?.order_count) {
                order.value = {
                  leastMost: filters?.order_count?.option,
                  value: filters?.order_count?.value,
                  value2: filters?.order_count?.value2
                }
              }
            }
          }
        }
      })

      watch([dataEstimate, errorEstimate, loadingEstimate], () => {
        if (loadingEstimate.value) {
          estimatedData.value = 0

          if (!notif.value) {
            notif.value = $q.notify({
              type: 'ongoing',
              color: 'primary',
              message: 'Estimating members in this segment...'
            })
          }
        } else {
          if (errorEstimate?.value) {
            const errMsg = 'There was a problem estimating segments.'

            showToast(
              errorEstimate?.value?.errors
                ? errorEstimate?.value?.errors[0]?.msg
                : errorEstimate?.value?.message ?? errMsg,
              'danger'
            )

            notif.value({
              type: 'negative',
              color: 'danger',
              message: errMsg
            })
          } else if (dataEstimate?.value) {
            estimatedData.value = dataEstimate?.value?.response

            notif.value({
              type: 'positive',
              color: 'success',
              message: `Estimated members in this segment: ${estimatedData.value}`
            })
          }

          notif.value = null
        }
      })

      watch([dataCreate, errorCreate, loadingCreate], () => {
        if (loadingCreate.value) {
          if (!notif.value) {
            notif.value = $q.notify({
              type: 'ongoing',
              color: 'primary',
              message: 'Saving customer segment...'
            })
          }
        } else {
          if (errorCreate?.value) {
            const errMsg = 'There was a problem saving customer segment.'

            showToast(
              errorCreate?.value?.errors
                ? errorCreate?.value?.errors[0]?.msg
                : errorCreate?.value?.message ?? errMsg,
              'danger'
            )

            notif.value({
              type: 'negative',
              color: 'danger',
              message: errMsg
            })
          } else if (dataCreate?.value) {
            notif.value({
              type: 'positive',
              color: 'success',
              message: `Customer segment successfully created.`
            })

            router.push({ name: 'customer_segment_list' })
          }

          notif.value = null
        }
      })

      watch([dataUpdate, errorUpdate, loadingUpdate], () => {
        if (loadingUpdate.value) {
          if (!notif.value) {
            notif.value = $q.notify({
              type: 'ongoing',
              color: 'primary',
              message: 'Updating customer segment...'
            })
          }
        } else {
          if (errorUpdate?.value) {
            const errMsg = 'There was a problem updating customer segment.'

            showToast(
              errorUpdate?.value?.errors
                ? errorUpdate?.value?.errors[0]?.msg
                : errorUpdate?.value?.message ?? errMsg,
              'danger'
            )

            notif.value({
              type: 'negative',
              color: 'danger',
              message: errMsg
            })
          } else if (dataUpdate?.value) {
            notif.value({
              type: 'positive',
              color: 'success',
              message: `Customer segment successfully updated.`
            })

            router.push({ name: 'customer_segment_list' })
          }

          notif.value = null
        }
      })

      watch([dataProduct, errorProduct, loadingProduct], () => {
        if (!loadingProduct.value) {
          if (errorProduct?.value) {
            const errMsg = 'There was a problem fetching SKUs.'

            showToast(
              errorProduct?.value?.errors
                ? errorProduct?.value?.errors[0]?.msg
                : errorProduct?.value?.message ?? errMsg,
              'danger'
            )
          } else if (dataProduct?.value) {
            product.value = {
              ...product.value,
              values: dataProduct?.value?.map(item => ({
                label: item?.sku_desc,
                value: item?.id
              }))
            }
          }
        }
      })

      watch([dataProduct2, errorProduct2, loadingProduct2], () => {
        if (!loadingProduct2.value) {
          if (errorProduct2?.value) {
            const errMsg = 'There was a problem fetching SKUs.'

            showToast(
              errorProduct2?.value?.errors
                ? errorProduct2?.value?.errors[0]?.msg
                : errorProduct2?.value?.message ?? errMsg,
              'danger'
            )
          } else if (dataProduct2?.value) {
            product2.value = {
              ...product2.value,
              values: dataProduct2?.value?.map(item => ({
                label: item?.sku_desc,
                value: item?.id
              }))
            }
          }
        }
      })
    })

    return {
      HELP,
      FORMAT,
      VALIDATION,
      DATE,
      getIconUrl,
      isUpdatePage,
      isLoading,
      loadingLists,
      loadingEstimate,
      loadingCreate,
      maxDate,
      minDate,
      maxDate2,
      minDate2,
      requiredField,
      showModal,
      showConfirmModal,
      filterForm,
      stepper,
      isUpdateAllowed,
      segmentStep,
      segmentStepEnd,
      segmentData,
      estimatedData,
      segmentCode,
      segmentName,
      segmentRemarks,
      customerType,
      customerDateFrom,
      customerDateTo,
      customerOptions,
      storeOptions,
      boughtOptions,
      basketOptions,
      orderOptions,
      isMyCustomers,
      enabledTime2,
      enabledProduct1,
      enabledProduct2,
      enabledBrand1,
      enabledBrand2,
      enabledCategory1,
      enabledCategory2,
      date,
      date2,
      store,
      product,
      product2,
      brand,
      brand2,
      category,
      category2,
      basket,
      order,
      goToListsPage,
      onStepNext,
      onStepPrevious,
      onStepSkip,
      onToggleModal,
      onToggleConfirmModal,
      onCloseConfirmModal,
      onSelectCustomerDateFrom,
      onSelectCustomerDateTo,
      onSelectFromDate,
      onSelectToDate,
      onSelectFromDate2,
      onSelectToDate2,
      onGetStoreOptions,
      onGetBrandOptions,
      onGetBrandOptions2,
      onGetCategoryOptions,
      onGetCategoryOptions2,
      onSelectSKU,
      onRemoveSKU,
      onSelectSKU2,
      onRemoveSKU2,
      onSubmitEstimate,
      onValidateSubmit,
      onSubmitCreate,
      addTime2,
      removeTime2,
      addProduct1,
      removeProduct1,
      addProduct2,
      removeProduct2,
      addBrand1,
      removeBrand1,
      addBrand2,
      removeBrand2,
      addCategory1,
      removeCategory1,
      addCategory2,
      removeCategory2,
      toggleEstimate,
      toggleCreate,
      getCustomerTypeName,
      getBoughtName,
      getLeastMostName
    }
  }
}
</script>

<style lang="scss" scoped>
ol {
  display: block;
  list-style: decimal;
  padding: 0 0 0 40px;

  li {
    list-style-position: outside;
    padding-left: 10px;
  }
}

.labelContainer {
  @apply mt-2;

  .labelText {
    @apply text-darkgray font-semibold;
  }
}

.listHeader {
  @apply font-bold;
  max-width: 200px;
}

.stepHeight {
  min-height: 300px;
}
</style>
