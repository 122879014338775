<template>
  <Banner variant="default">
    <div class="flex items-center gap-2">
      <q-icon name="info" size="xs" />
      {{ text }}
    </div>
    <slot />
  </Banner>
</template>

<script>
import Banner from '@/components/Banner'

export default {
  name: 'SegmentStepperBanner',
  components: {
    Banner
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
